<template>
  <div class="vg_wrapper">
    <el-card v-loading='isLoading'>
      <div>
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMpinsNow()">
          <el-row>
            <!-- <el-col :md="8">
              <el-form-item label="物料合同编号：">
                <el-input size="small" v-model.trim="searchForm.mcon_no" clearable placeholder="请填写物料合同编号"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :md="8">
              <el-form-item label="物料编号：">
                <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料名称：">
                <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="成品合同编号：">
                <el-input size="small" v-model.trim="searchForm.pcon_no" clearable placeholder="请填写成品合同编号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="10">
              <el-form-item label="核销状态：">
                <el-select v-model="searchForm.mpin_full" placeholder="请选择" size="small" clearable>
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMpinsNow()" class="vg_ml_16">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button> -->
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table  ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows" border>
            <el-table-column type="selection" width="48"  align="center"/>
            <el-table-column label="物料编号" prop="mtrl_no" />
            <!-- <el-table-column label="物料合同编号" prop="mcon_no" /> -->
            <el-table-column label="成品合同编号" prop="pcon_no" />
            <el-table-column label="物料名称" prop="mtrl_name" />
            <el-table-column label="物料单位" prop="mtrl_unit"/>
            <el-table-column label="计划入库" prop="mtrl_num"/>
            <el-table-column label="实际入库" prop="mpin_num"/>
            <el-table-column label="待入库时间" prop="mpin_date" :formatter="formatDate" />
            <el-table-column label="核销状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="getStatusName(scope.row.mpin_full).type" size="mini">{{getStatusName(scope.row.mpin_full).name}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {mpinAPI} from "@api/modules/mpin";
import pubPagination from "@/components/common/pubPagination";

export default {
  name: "MpinList",
  components: {
    pubPagination
  },
  data(){
    return{
      searchForm:{
        // mcon_no:'',
        pcon_no:'',
        mtrl_no:'',
        mtrl_name:'',
        mpin_full:'',
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      multiSelection: [],
      btn:{},
      options:[
        {
          value: 0,
          label: '未核销'
        },{
          value: 1,
          label: '已核销'
        }
      ],
      isLoading:true 
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/mpin_add' || from.path === '/mpin_edit'){
        this.initData()
      }
    },
  },
  methods: {
    initData(){
      this.isLoading = true 
      this.getMpinsList()
    },
    //获取物料采购列表
    getMpinsList(){
      get(mpinAPI.getMpins,
          {
            mcon_no: this.searchForm.mcon_no,
            pcon_no: this.searchForm.pcon_no,
            mtrl_no: this.searchForm.mtrl_no,
            mtrl_name: this.searchForm.mtrl_name,
            mpin_full: this.searchForm.mpin_full,
            page_no: this.currentPage,
          })
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              this.btn = res.data.data.btn;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
            setTimeout(()=>{
              this.isLoading = false 
            },500)
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        pcon_no:'',
        mtrl_no:'',
        mtrl_name:'',
        mpin_full:'',
      };
      this.currentPage = 1
      this.initData()
    },
    // 核销状态转换
    getStatusName(status) {
      if(status===0) {
        return {name:'未核销',type:'info'}
      } else if (status===1) {
        return {name:'已核销',type:'warning'};
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.mpin_date)
    },
    // 查询方法
    getMpinsNow(){
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.isLoading = true 
      this.getMpinsList()
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    // 分页查询
    changePageSearch(val){
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = val
      this.isLoading = true 
      this.getMpinsList()
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection){
      this.btn.delete = this.helper.isDelete(selection)
    },
    //删除提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.mpin_id)
      })
      post(mpinAPI.destroyMpinByIds,{'mpin_id_list':ids})
          .then(res=>{
            if(res.data.code === 0){
              let mg = res.data.msg;
              let tp = 'success';
              this.$message({message:mg,type:tp});
              this.initData()
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
              this.initData()
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
            this.initData()
          })
    },
  }
}
</script>

<style scoped>

</style>